import {useQuery} from '@tanstack/react-query';

import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {getProjectData} from './projects';

export const ProjectsQueryKeys = {
  all: ['projects'],
  currentProject: (projectId: string) => [...ProjectsQueryKeys.all, projectId] as const,
};

export function useCurrentProjectQuery(enabled: boolean = true) {
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ProjectsQueryKeys.currentProject(projectId), () => getProjectData(projectId), {
    enabled,
  });
}
