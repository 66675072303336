import {Router, useRouter} from 'next/router';
import {
  DependencyList,
  EffectCallback,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useHotkeys} from 'react-hotkeys-hook';
import {HotkeyCallback} from 'react-hotkeys-hook/dist/types';
import {useIsFirstRender} from 'usehooks-ts';

import {NAVIGATION_MAP} from '~constants/constants';
import {useCurrentProjectQuery} from '~api/projects.queries';
import {createDebouncer, hasOpenedDialogs, isInternalEmail, isProductionEnv} from '~utils/miscUtils';
import {
  confusionMatrixCellIdQueryParam,
  evaluationCellPredictedClassQueryParam,
  getRoute,
  Route,
  similaritySearchQueryParam,
  useQueryParam,
} from '~utils/routeUtil';
import {Dimensions, ImageOrderBy} from '~redux/types/images';
import {UserWithId} from '~redux/types/user';
import {selectPerspectives} from '~redux/reducers/filtersReducer';
import {selectUser} from '~redux/reducers/userReducer';
import {useAppDispatch, useAppSelector} from '~redux/index';

import {hideNotification, showNotification} from '~components/common/Notification';
import {UserRole} from 'src/contexts/AbilitiesContext';

/**
 * A debounced version of useEffect.
 *
 * @param effect The effect to debounce
 * @param delay delay in milliseconds
 * @param deps dependency array for the effect
 */
export function useDebouncedEffect(effect: EffectCallback, delay: number, deps?: DependencyList): void {
  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (isFirstRender) {
      return;
    }
    const timeout = setTimeout(effect, delay);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...(deps || [])]);
}

/**
 * A debounced version of useDispatch. For arguments see {@link createDebouncer}
 *
 * @param delay The delay to debounce the dispatch by
 * @param leadingEdge Whether to dispatch immediately on the first call
 * @returns A debounced version of dispatch
 */
export function useDebouncedDispatch(delay: number, leadingEdge?: boolean): (action: any) => void {
  const dispatch = useAppDispatch();
  const debouncedDispatch = useMemo(() => {
    const debouncer = createDebouncer(delay, leadingEdge);
    return debouncer(dispatch);
  }, [delay, dispatch, leadingEdge]);

  return debouncedDispatch;
}

type useMenuHandlerReturnType<T> = {
  anchorEl: HTMLElement | null;
  isOpen: boolean;
  handleClick: (event: React.MouseEvent<T>) => void;
  handleClose: () => void;
};

/**
 * Provides state and callbacks for MUI Menu handling.
 * Based on this MUI Menu demo: https://mui.com/material-ui/react-menu/#positioned-menu)
 *
 * @returns `{anchorEl, isOpen, handleClick, handleClose}`
 */
export function usePopupHandler<T extends HTMLElement>(customOnClick?: () => void): useMenuHandlerReturnType<T> {
  const [anchorEl, setAnchorEl] = useState<null | T>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<T>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
    customOnClick?.();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return {anchorEl, isOpen, handleClick, handleClose};
}

export function useConfirmRouteChange(isEnabled: boolean): {
  setRouteInterruptionEnabled: (isEnabled: boolean) => void;
  isRoutingInterrupted: boolean;
  continueRouting: () => void;
  cancelRouting: () => void;
} {
  const router = useRouter();
  const [enabled, setEnabled] = useState<boolean>(isEnabled);
  const [nextRoute, setNextRoute] = useState<string | null>(null);
  const lastHistoryState = useRef(global.history?.state);

  useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled]);

  const routeChangeStart = useCallback(
    (url: string) => {
      if (url === router.asPath) {
        // Do nothing if the next URL is the same as the current URL
        return;
      }

      setNextRoute(url);
      Router.events.emit('routeChangeError');

      // HACK - see https://github.com/vercel/next.js/issues/2476#issuecomment-850030407
      const state = lastHistoryState.current;
      if (state != null && history.state != null && state.idx !== history.state.idx) {
        history.go(state.idx < history.state.idx ? -1 : 1);
      }

      // eslint-disable-next-line no-throw-literal
      throw new Error('Abort route change. Please ignore this error.');
    },
    [router.asPath],
  );

  const continueRouting = useCallback(() => {
    if (!nextRoute) {
      return;
    }
    router.events.off('routeChangeStart', routeChangeStart);
    router.push(nextRoute);
    setNextRoute(null);

    // exclude unstable router from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextRoute, routeChangeStart]);

  const cancelRouting = useCallback(() => {
    setNextRoute(null);
  }, []);

  useEffect(() => {
    const storeLastHistoryState = () => {
      lastHistoryState.current = history.state;
    };
    router.events.on('routeChangeComplete', storeLastHistoryState);
    return () => {
      router.events.off('routeChangeComplete', storeLastHistoryState);
    };
    // exclude unstable router from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    router.events.on('routeChangeStart', routeChangeStart);
    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
    };
    // exclude unnecessary router.events from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, routeChangeStart]);

  return {
    setRouteInterruptionEnabled: (isEnabled: boolean) => setEnabled(isEnabled),
    isRoutingInterrupted: nextRoute !== null,
    continueRouting,
    cancelRouting,
  };
}

type UseIsAdminOptions = {
  validateEmail?: boolean;
};

export function useIsAdmin(options?: UseIsAdminOptions): boolean {
  const {validateEmail = true} = options || {};
  const currentProject = useCurrentProjectQuery();
  const currentUser = useAppSelector(selectUser);
  const userRole = currentProject?.data?.users.find((user) => user.email === currentUser?.email)?.role;
  return userRole === UserRole.ADMIN || (validateEmail && isInternalEmail(currentUser?.email));
}

export function useIsTrainer(): boolean {
  const currentProject = useCurrentProjectQuery();
  const currentUser = useAppSelector(selectUser);
  const userRole = currentProject?.data?.users.find((user) => user.email === currentUser?.email)?.role;
  return userRole === UserRole.TRAINER;
}

export function useUserList(): UserWithId[] {
  const currentProject = useCurrentProjectQuery();
  const currentUser = useAppSelector(selectUser);

  const userEmail = currentUser?.email;

  const filteredUserList = useMemo(() => {
    const users = currentProject.data?.users || [];
    return [...users].filter((user) => isInternalEmail(userEmail) || !isInternalEmail(user.email));
  }, [currentProject.data?.users, userEmail]);

  return filteredUserList;
}
export function useWorkInProgressNotification() {
  useEffect(() => {
    if (isProductionEnv()) {
      return;
    }
    const wipNotification = showNotification({
      id: 'wip-notification',
      title: 'Work In Progress',
      message: 'This page is unfinished, it is not visible in production.',
      duration: Infinity,
      severity: 'warning',
    });
    return () => hideNotification(wipNotification);
  }, []);
}

export function usePerspectiveName(perspectiveId?: number | null) {
  const perspectives = useAppSelector(selectPerspectives);

  if (perspectiveId === null) {
    return null;
  }

  const perspective = perspectives.find((perspective) => perspective.id === perspectiveId);
  return perspective?.name || perspectiveId?.toString();
}

export function useHotkeysWhenDialogsAreClosed(...args: Parameters<typeof useHotkeys>) {
  const [keys, callback, optionsOrDeps1, optionsOrDeps2] = args;
  const options = typeof optionsOrDeps1 === 'object' ? optionsOrDeps1 : optionsOrDeps2;
  const deps = typeof optionsOrDeps1 === 'object' ? optionsOrDeps2 : optionsOrDeps1;
  const [activeConfusionMatrixCellId] = useQueryParam(confusionMatrixCellIdQueryParam);
  const [evaluationCellPredictedClass] = useQueryParam(evaluationCellPredictedClassQueryParam);

  // Wrap the useHotkeys callback in a function that checks if any dialogs are open
  // and only calls the callback if not.
  const callbackWithDialogCheck = useMemo(
    (): HotkeyCallback =>
      (...args: Parameters<HotkeyCallback>) => {
        if (!activeConfusionMatrixCellId && !evaluationCellPredictedClass && hasOpenedDialogs()) {
          return;
        }
        callback(...args);
      },
    [activeConfusionMatrixCellId, callback, evaluationCellPredictedClass],
  );

  return useHotkeys(keys, callbackWithDialogCheck, options, deps);
}

/*
 * A modified version of useElementSize from usehooks-ts that uses ResizeObserver. In comparison to
 * the original version, this adapts to changes in the element's size, not just the window's size.
 * @see https://github.com/juliencrn/usehooks-ts/issues/236#issuecomment-1291001854
 */
export const useElementSize = <T extends HTMLElement = HTMLDivElement>(): [RefObject<T>, Dimensions] => {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Dimensions>([0, 0]);

  useLayoutEffect(() => {
    const element = ref.current;
    const updateSize = (element: Element | null) => {
      const {width, height} = element?.getBoundingClientRect() ?? {
        width: 0,
        height: 0,
      };
      setSize([width, height]);
    };

    updateSize(element);

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        updateSize(entry.target);
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return [ref, size];
};

function truncateUrl(url: string) {
  const segments = url.split('/');

  // remove the last segment
  segments.pop();

  return segments.join('/');
}

export const useGoBack = () => {
  const router = useRouter();
  const parentRoute = NAVIGATION_MAP[router.pathname];
  const [similaritySearch] = useQueryParam(similaritySearchQueryParam);

  // @ts-expect-error router.components does not exist on the NextRouter type, though it's present in the browser
  const parentRouteWithParams = router.components?.[parentRoute]?.resolvedAs as string;

  return useCallback(() => {
    if (parentRoute === Route.modelFinetune && router.pathname !== Route.modelFinetuneDeploymentSettings) {
      // @ts-expect-error same story
      const currentRouteWithParams = router.components?.[router.pathname]?.resolvedAs as string;
      router.push(currentRouteWithParams?.replace('/image', ''));
    } else if (router.pathname === Route.simsearchImage) {
      const simsearchRoute = getRoute(Route.simsearch, {
        query: {similaritySearch: similaritySearch as string, orderBy: ImageOrderBy.PROBABILITY_DESC},
      });
      router.push(simsearchRoute);
    } else if (parentRouteWithParams) {
      router.replace(parentRouteWithParams);
    } else {
      router.push(truncateUrl(router.pathname));
    }
  }, [parentRoute, parentRouteWithParams, router, similaritySearch]);
};
