import {Button, ButtonProps} from '@mui/material';
import React, {ReactNode} from 'react';

import {ToolbarTooltip} from '~components/common/toolbar/ToolbarTooltip';
import {styled} from 'src/constants/theme';
import {getCommonToolbarButtonStyles, ToolbarElementProps} from './toolbar.utils';
import {ToolbarDivider} from './ToolbarDivider';

const StyledButton = styled(Button)<ToolbarButtonProps>(getCommonToolbarButtonStyles);

export interface ToolbarButtonProps extends ButtonProps, ToolbarElementProps {
  /**
   * Should be a `Icon`, `SvgIcon` or a `@mui/icons-material` SVG icon element.
   */
  icon?: ReactNode;
  endIcon?: ReactNode;
  /**
   * If a title is passed, it is rendered instead of the `children`.
   */
  title?: string;
  children?: ReactNode;
}

/**
 * A customized MUI Button intended to be used inside the Toolbar component.
 */
export const ToolbarButton = React.forwardRef<HTMLButtonElement, ToolbarButtonProps>(function ToolbarButton(
  {icon, title, children, hasDivider, tooltip, ...rest}: ToolbarButtonProps,
  ref,
) {
  const button = (
    <StyledButton color='inherit' startIcon={icon} {...rest} ref={ref}>
      {title || children}
    </StyledButton>
  );

  return (
    <>
      {tooltip ? (
        <ToolbarTooltip title={tooltip}>
          <span>{button}</span>
        </ToolbarTooltip>
      ) : (
        button
      )}
      {hasDivider && <ToolbarDivider />}
    </>
  );
});
