import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {HTTPError} from 'ky';
import {UseFormReset} from 'react-hook-form';

import {Model} from '~redux/types/models';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {UpdateInferenceCriteriaFormData} from '~components/models/finetune/deployment-settings/useInferenceCriteriaSettingsForm';
import {useCurrentModel} from 'src/contexts/ModelContext';
import {showApiErrorNotification} from './api';
import {
  getAdvancedConfiguration,
  getCombinedPreview,
  getModelInferenceCriteria,
  getModels,
  getModelSelectionMetric,
  getModelTrainingConfig,
  getPreprocessingRois,
} from './models';

export const ModelsQueryKeys = {
  all: ['models'],
  allModels: (projectId: string) => [...ModelsQueryKeys.all, 'allModels', projectId] as const,
  modelTrainingConfiguration: (projectId: string, modelId?: string) =>
    [...ModelsQueryKeys.all, 'modelTrainingConfiguration', projectId, modelId] as const,
  modelSelectionMetric: (projectId: string, modelId: string) => [
    ...ModelsQueryKeys.all,
    'modelSelectionMetric',
    projectId,
    modelId,
  ],
  currentModelAdvancedConfiguration: (projectId: string, modelId?: string) => [
    ...ModelsQueryKeys.all,
    'currentModelAdvancedConfiguration',
    projectId,
    modelId,
  ],
  combinedPreview: (projectId: string, modelId?: string) => [
    ...ModelsQueryKeys.all,
    'combinedPreview',
    projectId,
    modelId,
  ],
  preprocessingRois: (projectId: string, modelId?: string) => [
    ...ModelsQueryKeys.all,
    'preprocessingRois',
    projectId,
    modelId,
  ],
  modelInferenceCriteria: (projectId: string, modelId?: string) => [
    ...ModelsQueryKeys.all,
    'modelInferenceCriteria',
    projectId,
    modelId,
  ],
} as const;

export function useModelsQuery(enabled: boolean = true): UseQueryResult<Model[]> {
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ModelsQueryKeys.allModels(projectId), ({signal}) => getModels(projectId, signal), {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: (data) => {
      return data
        .map((model) => ({
          // ensure that every label item has an alias by falling back to the label.id
          ...model,
          labels: model.labels.map((label) => ({...label, alias: label.alias || label.id})),
        }))
        .sort((modelA, modelB) => modelB.createdAt - modelA.createdAt);
    },
    enabled,
  });
}

export function useSingleModelQuery(modelId: string): UseQueryResult<Model | null> {
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ModelsQueryKeys.allModels(projectId), ({signal}) => getModels(projectId, signal), {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 60, // 1 hour
    select: (models) => models.find((model) => model.id === modelId) || null,
  });
}

export function useCurrentModelHasSamplesQuery(): UseQueryResult<boolean> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(ModelsQueryKeys.allModels(projectId), ({signal}) => getModels(projectId, signal), {
    refetchOnWindowFocus: true,
    staleTime: 1000 * 10,
    select: (models) => {
      const model = models.find((model) => model.id === currentModel?.id);
      return model ? model.imageCount > 0 : false;
    },
  });
}

export function useCurrentModelTrainingConfigurationQuery(
  isAdmin: boolean,
  modelId?: string,
): UseQueryResult<ModelTrainingConfigurationResponse> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);
  return useQuery(
    ModelsQueryKeys.modelTrainingConfiguration(projectId, modelId ?? currentModel?.id),
    ({signal}) => getModelTrainingConfig(projectId, modelId ?? currentModel!.id, signal),
    {
      enabled: (!!modelId || !!currentModel?.id) && isAdmin,
    },
  );
}

/**
 * Hook to fetch the selection metric for a model
 */
export function useModelSelectionMetricQuery(): UseQueryResult<SelectionMetricResponse> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(
    ModelsQueryKeys.modelSelectionMetric(projectId, currentModel!.id),
    ({signal}) => getModelSelectionMetric(projectId, currentModel!.id, signal),
    {
      enabled: Boolean(currentModel?.id),
      staleTime: 5 * 60 * 1000, // Cache for 5 minutes
    },
  );
}

export function useCurrentModelAdvancedConfigurationQuery(
  isAdmin: boolean,
): UseQueryResult<ModelAdvancedConfigurationResponse> {
  const projectId = useAppSelector(selectCurrentProjectId);
  const {currentModel} = useCurrentModel();

  return useQuery<ModelAdvancedConfigurationResponse, HTTPError>(
    ModelsQueryKeys.currentModelAdvancedConfiguration(projectId, currentModel?.id),
    () =>
      getAdvancedConfiguration({
        projectId,
        modelId: currentModel!.id,
      }),
    {
      enabled: Boolean(currentModel?.id && isAdmin),
      onError: showApiErrorNotification,
    },
  );
}

export function useCombinedPreviewQuery(enabled: boolean = false) {
  const projectId = useAppSelector(selectCurrentProjectId);
  const {currentModel} = useCurrentModel();

  return useQuery<CombinedPreviewResponse, HTTPError>(
    ModelsQueryKeys.combinedPreview(projectId, currentModel?.id),
    ({signal}) => getCombinedPreview({projectId, modelId: currentModel!.id, reactQuerySignal: signal}),
    {
      enabled: Boolean(currentModel) && enabled,
      staleTime: 1000 * 60,
      onError: showApiErrorNotification,
    },
  );
}

export function useModelInferenceCriteriaQuery(reset: UseFormReset<UpdateInferenceCriteriaFormData>) {
  const projectId = useAppSelector(selectCurrentProjectId);
  const {currentModel} = useCurrentModel();

  return useQuery<ModelInferenceCriteriaResponse, HTTPError>(
    ModelsQueryKeys.modelInferenceCriteria(projectId, currentModel?.id),
    ({signal}) => getModelInferenceCriteria(projectId, currentModel!.id, signal),
    {
      enabled: Boolean(currentModel),
      onError: showApiErrorNotification,
      staleTime: 1000 * 60,
      onSuccess: (data) => {
        reset(data);
      },
    },
  );
}

export function usePreprocessingRoisQuery(): UseQueryResult<ModelRoisResponse> {
  const {currentModel} = useCurrentModel();
  const projectId = useAppSelector(selectCurrentProjectId);

  return useQuery(
    ModelsQueryKeys.preprocessingRois(projectId, currentModel?.id),
    ({signal}) => getPreprocessingRois(projectId, currentModel!.id, signal),
    {
      enabled: Boolean(currentModel?.id),
      staleTime: 60 * 1000,
      onError: (error: HTTPError) => showApiErrorNotification(error),
    },
  );
}
