interface HotKey {
  keyCombination: string;
  label?: string;
}

interface HotKeys {
  [translationKey: string]: HotKey;
}

// Object keys are used as translation keys
export const hotKeys: HotKeys = {
  drawPolygon: {
    keyCombination: 'd',
  },
  nextImage: {
    keyCombination: 'left',
    label: '>',
  },
  previousImage: {
    keyCombination: 'right',
    label: '<',
  },
  zoomIn: {
    keyCombination: '+, =',
    label: '+',
  },
  zoomOut: {
    keyCombination: '-',
  },
  zoomTo100: {
    keyCombination: '0',
  },
  zoomToFit: {
    keyCombination: '1',
  },
  expandCollapseSidebar: {
    keyCombination: 's',
  },
  downloadImage: {
    keyCombination: 'i',
  },
  toggleLabels: {
    keyCombination: 'l',
  },
  toggleHumanAnnotations: {
    keyCombination: 'p',
  },
  toggleKeypointConnections: {
    keyCombination: 'k',
  },
  toggleMachinePredictions: {
    keyCombination: 'm',
  },
  toggleRegionOfInterest: {
    keyCombination: 'r',
  },
  toggleSimilarityPredictions: {
    keyCombination: 'n',
  },
  closePopups: {
    keyCombination: 'esc',
  },
  toggleGrid: {
    keyCombination: 'g',
  },
};
